.nav-link {
  @apply flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md ease-in-out duration-150;

  &:hover {
    @apply text-white bg-gray-700;
  }
  &:focus {
    @apply outline-none text-white bg-gray-700;
  }

  &.active {
    @apply text-white bg-gray-900;
  }
}

.navbar-link {
  @apply block no-underline text-primary-500 px-2 py-1 text-base;
  line-height: 30px;

  &:hover,
  &:focus {
    @apply text-primary-600;
  }

  &.active {
    @apply font-bold;
  }
}

@media (min-width: 1024px) {
  .navbar-link {
    @apply inline-flex my-0 mr-3;
    line-height: 40px;
  }
}

/* Highlights unread notifications with background color */
[data-target="notifications.notification"]:not([data-read-at]) {
  @apply bg-gray-100;
}

/* Highlights uninteracted notifications with blue dot */
[data-target="notifications.notification"]:not([data-interacted-at]) {
  [uninteracted] {
    @apply block;
  }
}

.dropdown-menu {
  min-width: 100%;

  @screen lg {
    min-width: 200px;
  }
}
