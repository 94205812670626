.form-group {
  @apply mb-6 relative;
}

.form-control {
  @apply bg-white appearance-none block w-full text-black border border-gray-400 rounded py-3 px-4 leading-none;

  transition: ease 0.2s border;

  &:focus {
    @apply shadow outline-none border-primary-500;

    box-shadow: 0 0 0 0.2rem theme("colors.primary.100");
    background-clip: padding-box;
  }

  &::placeholder {
    @apply text-gray-600;
  }

  &.error {
    @apply border-red-300;
  }
}

.form-icon-input {
  @apply pl-10;
}

label,
.label {
  @apply block text-sm font-medium leading-5 text-gray-700 mb-1 cursor-pointer;
}

.form-hint {
  @apply text-gray-500 text-xs;

  &.error {
    @apply text-red-600 text-xs leading-normal mt-2;
  }
}

.select {
  @apply appearance-none block w-full bg-white border border-gray-400 text-gray-700 rounded leading-tight;
  -webkit-appearance: none;
  padding: 12px 24px 12px 12px;
}

.select:focus {
  @apply outline-none bg-white border-primary-500;
}

.caret {
  @apply pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-800;
}

form.inline {
  .form-group {
    @apply flex justify-start items-center;
  }

  .form-group .input {
    @apply flex-1;
  }

  .form-group .label {
    @apply w-1/4;
  }
}

// Fix flatpickr
.flatpickr-current-month .flatpickr-monthDropdown-months {
  display: inline-block;
}

.form-checkbox {
  @apply text-primary-500 appearance-none inline-block h-4 w-4 border-gray-300 align-middle select-none flex-shrink-0 bg-white rounded;

  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  background-origin: border-box;
  //border: 1px solid rgb(209, 209, 209);
  box-shadow: inset 0 1px 1px rgba(#ddd, 0.8);
  background-size: 10px 10px;

  &:checked {
    //bg-current
    @apply border-transparent bg-gray-500 bg-center bg-no-repeat shadow-none;
    background-image: url("../images/icons/checkmark.svg");
    background-size: 10px 10px;

    &:focus {
      @apply border-transparent;
    }
  }

  &:disabled {
    @apply bg-gray-400;
  }

  &:focus {
    @apply ring-indigo-500;
    box-shadow: 0 0 0 3px rgba(164, 202, 254, 0.45);
    border-color: theme("colors.primary.300");
  }
}

.input-file {
  height: auto;
  z-index: 2;
  cursor: pointer;

  @apply opacity-0 inline-block pl-0 pr-0 py-3 px-3 overflow-hidden absolute border-none;

  + label {
    @apply inline-flex text-sm font-bold text-center px-4 py-0 rounded no-underline items-center justify-between cursor-pointer;

    height: 40px;
    line-height: 40px;
    transition: ease 0.3s background, ease 0.3s transform, ease 0.2s color;

    @extend .btn;
    @extend .btn-tertiary;
    @extend .outline;

    &:focus {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
  }
}

.acomplete {
  width: 100%;
  border: 1px solid #000;
  padding: 1rem;
}
