@import "tailwindcss/base";
@import "tailwindcss/components";

@import "components/base";
@import "components/announcements";
@import "components/alert";
@import "components/avatars";
@import "components/typography";
@import "components/buttons";
@import "components/icons";
@import "components/forms";
@import "components/util";
@import "components/nav";
@import "components/maps";
@import "components/code";
@import "components/docs";
@import "components/animation";
@import "components/tabs";
@import "components/pagination";
@import "components/connected_accounts";
@import "components/actiontext";
@import "components/direct_uploads";
@import "components/trix";
@import "components/iframe";
@import "components/algolia";

@import "tailwindcss/utilities";

@import "tippy.js/dist/tippy.css";
@import "flatpickr/dist/flatpickr.css";

//@import "leaflet/dist/leaflet";
