.announcement-new {
  @apply bg-blue-500 text-white;
}

.announcement-fix {
  @apply bg-red-600 text-white;
}

.announcement-update {
  @apply bg-green-500 text-white;
}

.announcement-improvement {
  @apply bg-purple-600 text-white;
}

.unread-announcements span:before {
  @apply bg-red-600;

  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  content: "";
  display: inline-block;
  height: 8px;
  width: 8px;
  margin-right: 6px;
}
